import { useParams } from "react-router-dom";
const axios = require("axios");

const Api = () => {
  const base_link = "https://api.kiosk.t05pay.sg";
  // const base_link = "http://localhost:3010";
  const { id, itemId, kioskId } = useParams();

  const request = async (endpoint, data, method, headers = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        endpoint = `${base_link}${endpoint}`;
        let response;

        if (method === "POST") {
          response = await axios.post(endpoint, data, {
            headers,
            withCredentials: true,
          });
        }
        if (method === "GET") {
          response = await axios.get(endpoint, {
            headers,
            withCredentials: true,
          });
        }
        if (method === "DELETE") {
          response = await axios.delete(endpoint, {
            headers,
            withCredentials: true,
          });
        }

        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const post = (endpoint, data, headers) => {
    return request(endpoint, data, "POST", headers);
  };

  const get = (endpoint, headers) => {
    return request(endpoint, null, "GET", headers);
  };

  const deleteItem = (endpoint, headers) => {
    return request(endpoint, null, "DELETE", headers);
  };

  // Login & Register
  const login = (data) => {
    return post(`/user/login`, data);
  };
  const register = (data) => {
    return post(`/user/register`, data);
  };

  // Outlet
  const getOutletList = () => {
    return get(`/outlet/list`);
  };
  const getSingleOutlet = () => {
    return get(`/outlet/${id}`);
  };
  const createOutlet = (data) => {
    return post(`/outlet/create`, data);
  };

  const editOutlet = (data) => {
    return post(`/outlet/edit`, data);
  };
  const editColor = (data) => {
    return post(`/outlet/edit/colors`, data);
  };

  const deleteOutlet = (outletId = id) => {
    return deleteItem(`/outlet/${outletId}`);
  };

  const getKioskPin = (kioskId) => {
    return get(`/outlet/pin/generate/${kioskId}`);
  };

  const getPairedKiosks = () => {
    return get(`/outlet/${id}/kiosk/list`);
  };

  const unpairKiosk = (data) => {
    return post(`/outlet/kiosk/unpair`, data);
  };

  const pairKiosk = (data) => {
    return post(`/outlet/kiosk/pair`, data);
  };
  const createKiosk = (data) => {
    return post(`/outlet/kiosk/create`, data);
  };
  const deleteKiosk = (kioskId) => {
    return deleteItem(`/outlet/kiosk/${kioskId}`);
  };
  const getKiosk = () => {
    return get(`/outlet/kiosk/${kioskId}`);
  };
  const editKiosk = (data) => {
    return post(`/outlet/kiosk/edit`, data);
  };
  // Menu
  const getMenusList = () => {
    return get(`/menu/list`);
  };

  const createMenu = (data) => {
    return post(`/menu/create`, data);
  };

  const getSingleMenu = () => {
    return get(`/menu/${id}`);
  };

  const editMenu = (data) => {
    return post(`/menu/${id}/edit`, data);
  };

  const getMenuCategories = (menuId) => {
    return get(`/menu/${menuId}/categories`);
  };

  const deleteMenu = () => {
    return deleteItem(`/menu/${id}`);
  };

  const getMenusById = () => {
    return get(`/menu/${id}/item/list`);
  };

  const createItem = (data) => {
    return post(`/menu/${id}/item/create`, data);
  };

  const getSingleMenuItem = () => {
    return get(`/menu/${id}/item/${itemId}`);
  };

  const editItem = (data) => {
    return post(`/menu/${id}/item/edit`, data);
  };

  const deleteMenuItem = () => {
    return deleteItem(`/menu/${id}/item/${itemId}`);
  };

  const getCategory = () => {
    return get(`/menu/${id}/categories`);
  };
  //Subscription
  const getSubscriptionKey = () => {
    return get(`/rsa/encrypt`);
  };

  const addNewSubscription = (data) => {
    return post(`/subscription/new`, data);
  };

  const getSubscriptionDetails = () => {
    return get(`/subscription`);
  };

  const changePaymentCard = (data) => {
    return post(`/subscription/payment/tokenize`, data);
  };

  const getCardDetails = (data) => {
    return post(`/subscription/card/details`, data);
  };

  const saveCardId = (data) => {
    return post(`/subscription/card/save`, data);
  };

  const getPaymentHistory = () => {
    return get(`/subscription/payment/history`);
  };

  const renewSubscription = () => {
    return get(`/subscription/renew`);
  };

  const toggleAutoRenewal = () => {
    return get(`/subscription/toggleRenew`);
  };
  const addSubscription = (data) => {
    return post(`/subscription/add`, data);
  };
  const downgradeSubscription = (data) => {
    return post(`/subscription/downgrade`, data);
  };

  const getSubscriptionPrice = () => {
    return get(`/subscription/price`);
  };

  //change password
  const changePassword = (data) => {
    return post(`/user/password/change`, data);
  };

  //logout
  const logout = () => {
    return get(`/user/logout`);
  };

  // authenticated
  const getAuthenticated = () => {
    return get(`/user/authenticated`);
  };

  // Transaction
  const getTransactions = () => {
    return get(`/transaction/list`);
  };
  return {
    login,
    register,
    getOutletList,
    getSingleOutlet,
    createOutlet,
    editOutlet,
    editColor,
    deleteOutlet,
    getKioskPin,
    getPairedKiosks,
    unpairKiosk,
    pairKiosk,
    createKiosk,
    deleteKiosk,
    getKiosk,
    editKiosk,
    getMenusList,
    createMenu,
    getSingleMenu,
    editMenu,
    getMenuCategories,
    deleteMenu,
    getMenusById,
    createItem,
    getSingleMenuItem,
    editItem,
    deleteMenuItem,
    getCategory,
    getSubscriptionKey,
    addNewSubscription,
    getSubscriptionDetails,
    changePaymentCard,
    getCardDetails,
    saveCardId,
    getPaymentHistory,
    renewSubscription,
    toggleAutoRenewal,
    addSubscription,
    getSubscriptionPrice,
    downgradeSubscription,
    changePassword,
    logout,
    getAuthenticated,
    getTransactions,
  };
};

export default Api;
