import React, { lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import useApi from "./apiHooks/apiHooks";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const PaymentPending = lazy(() => import("./pages/PaymentPending"));

function App() {
  const { getAuthenticated } = useApi();
  const isAuthenticated = async (to, from, next) => {
    if (to.match.path === "/") {
      const {
        data: { status },
      } = await getAuthenticated();
      if (status === "success") next.redirect("/app");
      else {
        next();
      }
    }
    next();
  };

  const requireAuth = async (to, from, next) => {
    if (to.meta.requireAuth) {
      const {
        data: { status },
      } = await getAuthenticated();
      if (status === "success") next();
      else {
        next.redirect("/");
      }
    }
    next();
  };

  return (
    <>
      <GuardProvider guards={[isAuthenticated, requireAuth]}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <GuardedRoute path="/" component={Login} exact />
          <GuardedRoute path="/app" component={Layout} meta={{ requireAuth: true }} />
          <GuardedRoute path="/create-account" component={CreateAccount} />
          <GuardedRoute path="/change-password" component={ChangePassword} />
          <GuardedRoute path="/payment/pending" component={PaymentPending} />
          {/* Place new routes over this */}
          {/* If you have an index page, you can remothis Redirect */}
          {/* <Redirect exact from="/" to="/login" /> */}
          <Redirect from="*" to="/" />
        </Switch>
      </GuardProvider>
    </>
  );
}

export default App;
